import { defineStore } from 'pinia'

export const useReportStore = defineStore('reportStore', () => {
  const dsInterface = useDSInterface()
  const route = useRoute()

  const reportView = computed(() => {
    const lastRouteName = route.fullPath.split('/').pop()

    const pages = ['class', 'teacher', 'project', 'student']

    if (!pages.includes(lastRouteName)) {
      return 'general'
    }

    return lastRouteName
  })

  const goToReportPage = (page: 'general' | 'class' | 'teacher' | 'project' | 'student') => {
    if (page === 'general') {
      return navigateTo(`/reporting/${route.params.reportingId}`)
    }

    navigateTo(`/reporting/${route.params.reportingId}/${page}`)
  }

  const { data: responseReport, pending: reportLoading } = useAsyncData(
    'customer-report',
    async () =>
      await dsInterface.report().findOne({
        id: route.params.reportingId,
        include: ['periods', 'customer', 'customers'],
      }),
    {
      transform: (response) => {
        return response?.data || null
      },
    },
  )

  return {
    report: responseReport,
    reportLoading,
    reportView,
    goToReportPage,
  }
})
